import { Asset } from 'contentful'
import React, { useEffect, useState } from 'react'

// Services
import { getAsset } from 'services/contentful'

// Styling
import './asset-viewer.scss'

interface Props {
  id?: string
}

export const AssetViewer: React.FC<Props> = ({ id }) => {
  const [asset, setAsset] = useState<Asset>()

  useEffect(() => {
    async function fetchAsset(id: string) {
      const cfAsset = await getAsset(id)
      setAsset(cfAsset)
    }

    if (id) {
      fetchAsset(id)
    }
  }, [id])

  return (
    <div className={'asset-viewer'}>
      {asset && (
        <a
          href={asset.fields.file.url}
          title={`Ladda ned ${asset.fields.file.fileName}`}
          download={asset.fields.file.fileName}
          className={'asset-viewer__asset-link'}
        >
          <img
            src={asset.fields.file.url}
            alt={asset.fields.file.fileName}
            width={asset.fields.file.details.image?.width}
            height={asset.fields.file.details.image?.height}
          />
        </a>
      )}
      {asset && (
        <a
          href={asset.fields.file.url}
          title={`Ladda ned ${asset.fields.file.fileName}`}
          download={asset.fields.file.fileName}
          className={'asset-viewer__button'}
        >
          Ladda ned
        </a>
      )}
    </div>
  )
}

import { AssetViewer } from 'components/AssetViewer'
import React from 'react'
import { WindowLocation } from '@reach/router'
import useQueryString from 'use-query-string'

// Components
import Layout from '../components/layout'

// Styling
import '../styling/pages/assets.scss'

interface Props {
  location: WindowLocation
}

const AssetsPage: React.FC<Props> = ({ location }) => {
  const [query] = useQueryString(location)

  return (
    <Layout theme="Dark">
      <div className={'assets-page'}>
        <AssetViewer id={query.id} />
      </div>
    </Layout>
  )
}

export default AssetsPage
